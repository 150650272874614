import React, { Component } from 'react';
import './App.css';
import Web3 from 'web3';
import saleAbi from './contracts/KSale_abi.json'
import tokenAbi from './contracts/KCoin_abi.json'


const saleAddr  = '0x7FE25B02173c85b51DB574eF7968d4A0b89A0091'
const tokenAddr = '0x5d394558535D8846B198341f9691c318974b45Ee'





class Sale extends Component {
  web3Provider: null;
  web3: null;
  tokenContr: null;
  saleContr: null;

  constructor(props){
    super(props);
    this.state = {
      account:null,
      numberOfTokens:'',
      currentBalance:null,
      tokensSold:'',
      metamaskDetected:false,
    }
  }

  async componentDidMount(){
    await this.initProvider();
    await this.initWeb3();
  }

  async initProvider() {
    // Modern dSale browsers...
    if (window.ethereum) {
      Sale.web3Provider = window.ethereum;
      this.setState({metamaskDetected:true})
      try {
        // Request account access
        await window.ethereum.enable();
      } catch (error) {
        // User denied account access...
        console.error("User denied account access")
      }
    }
    // Legacy dSale browsers...
    else if (window.web3) {
      Sale.web3Provider = window.web3.currentProvider;
      this.setState({metamaskDetected:true})
    }
    // If no injected web3 instance is detected, fall back to Ganache
    else {
    }
  }  

  async initWeb3(){
    if(this.state.metamaskDetected){
      Sale.web3 = new Web3(Sale.web3Provider);
      Sale.tokenContr = new Sale.web3.eth.Contract(tokenAbi,tokenAddr);
      Sale.saleContr = new Sale.web3.eth.Contract(saleAbi,saleAddr);
      return this.initAccounts();
      }
  }

  async initAccounts(){
    Sale.web3.eth.getAccounts().then((y) => {
    this.setState({
       account: y[0],
    })
    
    
    Sale.tokenContr.methods.balanceOf(this.state.account).call().then((b)=>{
      this.setState({
        currentBalance: b/10**18
      })
      console.log("");
    })
    }).catch((e)=> console.log("",e));
 
    Sale.saleContr.methods.tokensSold().call().then((t)=>{
      this.setState({
        tokensSold: t
      })
    }) 
  }


  async componentDidUpdate(prevProps, prevState) {
    if(this.state.metamaskDetected && Sale.web3){
    window.ethereum.on('accountsChanged', (newAccount)=> {
       this.setState({
        accounts:newAccount,
       })    
      
      this.initAccounts();
     
    })
  }
  }


  handleNumberOfTokensChange =(event) => {
    console.log("from handelNumberOfTokensChange",event.target.value);
    this.setState({
       numberOfTokens: event.target.value
    })
  }

  handleSubmit= (event) =>{


    event.preventDefault();
    event.stopPropagation();
    if( Sale.web3.utils.isAddress(this.state.account) && !isNaN(this.state.numberOfTokens)){
        var a = this.state.account;
        var T = this.state.numberOfTokens;
        var N = T*10**18
        var p = 300000;
        var f = 10**9;
        var n = N/f;
        var v = n*p
        
        console.log("T=",T);
        console.log("N=",N);
        console.log("n=",n);
        console.log("v=",v);
    
     
        Sale.saleContr.methods.buyTokens(n.toString()).send({from : a, value : v.toString()}).
        then((tx)=>{
          this.initAccounts();      
        }).
        catch((e)=>{console.log(e)});


    }else{
        console.log("")
    }
  }
  



  render(){
     if(this.state.metamaskDetected){
       return(
         <div>
           <h1>Token Distribution</h1>
           <form onSubmit={this.handleSubmit}>
              <div>
                 <input type="text" value={this.state.numberOfTokens} onChange={this.handleNumberOfTokensChange}></input>
              </div>
              <div>
                 <button type="submit">Claim Tokens</button>
              </div>
           </form>
           <h2>You currently have {this.state.currentBalance} tokens</h2>
         </div>
       )
     }else{
       return(
         <a href="https://www.metamask.io"> install Metamask</a>
       )
     }
  }
  
}


export default Sale;

////           <h2>Tokens sold {this.state.tokensSold} </h2>


