import React, { Component } from 'react';

class contacts extends Component {


    render() {
        return ( <
            div >
            <
            h1 style = {
                { color: 'black' }
            } > Contact < /h1>

            <
            p >
            Please email us at stan.sredn @gmail.com <
            /p>


            <
            /div>
        );
    }

}

export default contacts;