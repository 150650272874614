import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Redirect, Switch, useLocation } from 'react-router-dom';

import { withRouter } from 'react-router'

import bs from './bootstrap.min.css';
//  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

import Home from './Home.js';
import About from './About.js';
//import Whitepaper from './Whitepaper.js';
//import Presentation from './Presentation.js';
import Ico from './Ico.js';
import Contacts from './Contacts.js';
//import Pdf_render from './Pdf_render.js';
import Navigation from './Navigation.js';

import Signup from './Signup.js';
import  Meetup from './Meetup';
import  Docs from './Docs1';
import  Protocol from './Protocol';


import Web3 from 'web3';


import NavBar, { ElementsWrapper } from 'react-scrolling-nav';


import { Helmet } from 'react-helmet'

const TITLE = 'Distributed Search Engine'



const Zuk = () => < h1 > Error < /h1>;

const Err = () => < h1 > Error < /h1>;



class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "message",
        }
    }

    componentDidMount(){
        document.title = "Rorur Distributed search engine"
    }


    render() {



        return ( <
            div >
            <
            Helmet >
            <
            title > { TITLE } < /title> < /
            Helmet >


            <
            BrowserRouter >
            <
            div >
            <
            Navigation style = {
                { color: 'black' }
            }
            /> <
            Switch >
            <
            Route path = "/"
            component = { Home }
            exact / >

            <
            Route path = "/About"
            component = { About }
            exact / >

            <
            Route path = "/Whitepaper"
            component = {
                () => { window.location.replace( './kcoin2.pdf'); return null; }
            }
            exact / >
 

            <
            Route path = "/Roadmap"
            component = {
                () => { window.location.replace("./roadmap3.pdf"); return null; }
            }
            exact / >

            <
            Route path = "/Rank"
            component = {
                () => { window.location.replace("./rank.pdf"); return null; }
            }
            exact / >


            <
            Route path = "/Signup"
            component = { Signup }
            exact / >


            <
            Route path = "/Meetup"
            component = { Meetup }
            exact / >




            <
            Route path = "/Presentation"
            component = {
                () => { window.location.replace('https://www.youtube.com/watch?v=BRavAcplYOA'); return null; }
            }
            exact / >

            <
            Route path = "/Github"
            component = {
                () => { window.open("https://github.com/cnn-rnn"); return null; }
            }
            exact / >


            <
            Route path = "/Docs"
            component = { Docs }
            exact / >

            <
            Route path = "/Protocol"
            component = { Protocol }
            exact / >

            <
            Route path = "/Knowledge"
            component = {
                () => { window.location.replace("./kr1.pdf"); return null; }
            }
            exact / >



            <
            Route path = "/Ico"
            component = { Ico }
            exact / >

            <
            Route path = "/Contacts"
            component = { Contacts }
            exact / >



            <
            Route path = "/Twitter"
            component = {
                () => { window.location.replace("https://twitter.com/rorur_"); return null; }
            }
            exact / >



            <
            Route path = "/Cluster"
            component = {
                () => { window.location.replace('http://174.138.39.149:3000/cluster_info'); return null; }
            }
            exact / >



            <
            Route component = { Err }
            />

            <
            /Switch> < /
            div > <
            /BrowserRouter> < /
            div >
        );
    }
}


export default App;



//            <Route component={Error}/>