import { useState } from 'react';
//import ReactDOM from 'react-dom/client';

import React, { Component}  from 'react';

class signup extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        Name:"",
        Email:"",
        NumberOfNodes:"",
      }

      this.state.formStyle = {
        "text-align": "center"
      };
      
      //      this.handleChange = this.handleChange.bind(this);



        this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleName =(event) => {
        console.log("from handleNamae",event.target.value);
        this.setState({
           Name: event.target.value
        })
    }
    handleEmail =(event) => {
        console.log("from handleEmail",event.target.value);
        this.setState({
           Email: event.target.value
        })
    }
    handleNumberOfNodes =(event) => {
        console.log("from handleNumberOfNodes",event.target.value);
        this.setState({
           NumberOfNodes: event.target.value
        })
    }
    

  
    handleSubmit = async (event) => {
      event.preventDefault();
      event.stopPropagation();


      try {
        let res = await fetch("https://rorur.com/post", {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            Name: this.state.Name,
            Email: this.state.Email,
            NumberOfNodes: this.state.NumberOfNodes,
          }),
        });

        console.log("res=",res)


        //let resJson = await res.json();
        if (res.status === 200) {
//            alert("resp 200")
        } else {
            alert("there was an error, status="+res.status)
        }

//        console.log("hhhhhhhhh")

        alert("You successfully signed up");

    } catch (err) {
        console.log(err);
        alert(err)
      }

    }

    render() {
      return (
        <form style={this.state.formStyle} onSubmit={this.handleSubmit}>
        Name:{"\n"}
        <div>
           <input  type="text" value={this.state.Name} onChange={this.handleName}></input>
        </div>
        Email:{"\n"}
        <div>            
           <input type="text" value={this.state.Email} onChange={this.handleEmail}></input>
        </div>
        Number of nodes:{"\n"}
        <div>            
           <input type="text" value={this.state.NumberOfNodes} onChange={this.handleNumberOfNodes}></input>
        </div>
        <div>
           <button type="submit">Submit</button>
        </div>
     </form>
      )
    }
  }




  export default signup;

