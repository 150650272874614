import React , { Component} from 'react';
import Sale from "./Sale.js"


class Ico extends Component {
    
    
    
    
  render(){
    return(
     <div> 
       <h1> Explanation  of goals</h1>
<p>
As explained in the white paper and in the "About" section, there are certain financial needs that have to be fulfilled for the success of this project. Therefore, we are conducting this crowd funding campaign. This project is designed for the public benefit and you should think about participating in this crowd funding as a donation rather than investment. However, there is certain possibility that this project will become commercially successful, and in such a case, for the benefit of our supporters ( if any) we created an Ethereum token. The token symbol is KCOIN and its contract can be found  <a href="https://etherscan.io/address/0x5d394558535D8846B198341f9691c318974b45Ee"> here </a>. It is an ERC20 token. We issued 10^9 tokens, having 18 decimals. We currently have very little experience with crowd funding, and therefore reserved in our distribution contract the full control over the price of the token, amount to be distributed (out of 10^9), and the time frame of the crowd funding campaign. We can change these variables at any moment depending on public reaction. At the moment you can obtain 1 token for  3*10^14wei, i.e. if you support us with 1 Ether, we will send you 3333 of the tokens in appreciation of your support. You can use the form below to enter the number of tokens you want to obtain in return of your donation. You must have Metamask installed.
</p>
     <Sale/>
     </div> 
    )

  }
 
}
export default Ico;




