import React from 'react';

import { NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'reactstrap';

import { LinkContainer } from "react-router-bootstrap";


const Navigation = () => {
    return ( <
        Navbar bg = "light"
        expand = "lg" >
        <
        Nav className = "ml-auto"
        style = {
            { padding: '10px' }
        } >
        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/" > Home < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/About" > About < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Whitepaper" > Whitepaper < /NavLink>


        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Roadmap" > Roadmap < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Rank" > Rank specification < /NavLink>



        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Signup" > Signup < /NavLink>


        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Meetup" > Virtual meetup < /NavLink>



        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Presentation" > Presentation < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Github" > Github < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Docs" > Docs < /NavLink>


        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Protocol" > Protocol < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Knowledge" > Knowledge Representation < /NavLink>


        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Ico" > Ico < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Contacts" > Contacts < /NavLink>

        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Twitter" > Twitter < /NavLink>


        <
        NavLink style = {
            { padding: '10px', color: 'black' }
        }
        to = "/Cluster" > Cluster < /NavLink>


        </Nav> </Navbar>
    );
}

export default Navigation;