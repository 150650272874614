import React, { Component}  from 'react';
 
class about extends Component {


 render(){
    return (
       <div>
          <h1 style={{color:'black'}}>Meetup</h1>
          
<p>          
Weekly virtulal meetup takes place at 6pm EST at <a href="https://duke.zoom.us/j/2873815550" >   https://duke.zoom.us/j/2873815550  </a>
</p>

<p>
See more at <a href = "https://www.meetup.com/decentralized-search-knowledge-coin/">https://www.meetup.com/decentralized-search-knowledge-coin/ </a>

</p>
        
       </div>
    );
}

}
 
export default about;




